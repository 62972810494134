/* eslint-disable no-undef */
import React, { useRef } from 'react';
import './UploadForm.scss';

function UploadForm({ handleClick, questionId }) {
  const inputFile = useRef(null);
  // const [open, setOpen] = useState(false);
  // const [openPicture, setOpenPicture] = useState(false);

  // const deviceType = () => {
  //   const ua = navigator.userAgent;
  //   if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
  //     return 'tablet';
  //   }
  //   if (
  //     /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
  //       ua,
  //     )
  //   ) {
  //     return 'mobile';
  //   }
  //   if (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 0) {
  //     return 'ipad';
  //   }
  //   return 'desktop';
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpenModal = () => {
  //   setOpen(true);
  // };

  // const handleClosePicture = () => {
  //   setOpenPicture(false);
  // };

  // const handleOpenModalPicture = () => {
  //   setOpenPicture(true);
  // };

  const handleUploadFile = () => {
    inputFile.current.click();
  };

  // const handleUpload = () => {
  // if (deviceType() === 'desktop') {
  // handleUploadFile();
  // } else {
  //   handleOpenModal();
  // }
  // };

  return (
    <>
      <button type="button" className="btn__upload" onClick={handleUploadFile}>
        <input
          type="file"
          id="file"
          accept="image/png, image/jpg, image/jpeg"
          ref={inputFile}
          onChange={(e) => {
            // handleClose();
            handleClick(e, questionId);
          }}
          style={{ display: 'none' }}
          multiple
        />
        画像を投稿する
      </button>
      {/* 
        <ModalSelectUpload
          open={open}
          handleClose={handleClose}
          handleUploadFile={handleUploadFile}
          handleOpenPic={handleOpenModalPicture}
        />

        <ModalTakePicture
          open={openPicture}
          handleClose={handleClosePicture}
          questionId={questionId}
          handleClickModal={(source) => {
            handleCamera(source, questionId);
            handleClose();
            handleClosePicture();
          }}
        />
      */}
    </>
  );
}

export default UploadForm;
