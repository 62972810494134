import React from 'react';
import DropdownItem from '../components/common/form-questions/dropdown/DropDownItem';
import MultipleChoiceItem from '../components/common/form-questions/form-checkbox/MultipleChoiceItem';
import IconChoiceItem from '../components/common/form-questions/icon-choices/IconChoiceItem';
import ImageChoiceItem from '../components/common/form-questions/image-choices/ImageChoiceItem';
import ImageRadioChoice from '../components/common/form-questions/image-radio-choices/ImageRadioChoice';
import RadioChoiceCustom from '../components/common/form-questions/radio-choices/RadioChoiceCustom';
import RadioChoiceItem from '../components/common/form-questions/radio-choices/RadioChoiceItem';
import StarChoiceItem from '../components/common/form-questions/star-choices/StarChoiceItem';
import TextFormItem from '../components/common/form-questions/text-form/TextFormItem';
import TextAreaItem from '../components/common/form-questions/textarea-choices/TextAreaItem';
import RadioGroupForm from '../components/common/group-form/RadioGroupForm';
import './Dummy/Dummy.scss';

export default function Demo() {
  return (
    <div className="wrapper">
      <main>
        <DropdownItem />
        <MultipleChoiceItem />
        <IconChoiceItem />
        <ImageChoiceItem />
        <ImageRadioChoice />
        <RadioChoiceCustom />
        <RadioChoiceItem />
        <StarChoiceItem />
        <TextFormItem />
        <TextAreaItem />
        <RadioGroupForm />
      </main>
    </div>
  );
}
