import React, { useEffect, useState } from 'react';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import constants from '../../../../constants';
import './TextAreaItem.scss';
import Letter from './format/letter';
import Number from './format/number';
import Alphanumeric from './format/alphanumeric';
import Email from './format/email';
import Date from './format/date';
import Password from './format/password';

const { DATA_FORMAT_ENUM, DATA_FORMAT_REGEX } = constants;

const formatComponents = {
  [DATA_FORMAT_ENUM.letters]: <Letter />,
  [DATA_FORMAT_ENUM.numbers]: <Number />,
  [DATA_FORMAT_ENUM.alphanumeric]: <Alphanumeric />,
  [DATA_FORMAT_ENUM.email]: <Email />,
  [DATA_FORMAT_ENUM.date]: <Date />,
  [DATA_FORMAT_ENUM.password]: <Password />,
};

function TextAreaItem({ isHide, isRequired, format, title, isSentences, onChange, onError }) {
  const [isShowError, setIsShowError] = useState(false);
  const [isValid, setIsValid] = useState(!isRequired);

  useEffect(() => {
    if (!isHide) onError(!isValid);
    else onError(false);
  }, [isValid, isHide]);

  const handleChange = (value) => {
    if (!isShowError) setIsShowError(true);

    if (
      (value &&
        (DATA_FORMAT_REGEX[format].test(value) || DATA_FORMAT_REGEX.phoneNumber.test(value))) ||
      (!value && !isRequired)
    ) {
      setIsValid(true);
      onChange(value);
      return;
    }

    setIsValid(false);
  };

  return (
    <div className="textarea_form">
      <div className="form__title">
        {title}
        {isRequired && <span className="form__require">*</span>}
      </div>
      <div className="form-check">
        <div className="input">
          {React.createElement(formatComponents[format].type, {
            isSentences,
            onChange: (value) => {
              handleChange(value);
            },
          })}
        </div>
        {isShowError && (!isValid ? <BsXLg color="red" /> : <BsCheckLg color="green" />)}
      </div>
    </div>
  );
}

export default TextAreaItem;
