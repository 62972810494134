import React, { useState } from 'react';

export default function Number({ onChange }) {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value.trim());

    if (value !== event.target.value.trim()) onChange(event.target.value.trim());
  };

  return (
    <>
      <input className="form-control" value={value} onChange={handleChange} />
      <div className="text_format">(半角数字)</div>
    </>
  );
}
