import React from 'react';
import './PreviewImage.scss';
import CloseButton from 'react-bootstrap/CloseButton';

function PreviewImage({ linkImage, indexImage, removeImage }) {
  return (
    <div className="preview_image">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className="preview_image_icon"
        role="button"
        tabIndex={0}
        onClick={() => removeImage(indexImage)}
      >
        <CloseButton />
      </div>
      <div>
        <img
          src={`${process.env.APP_DOMAIN}${encodeURIComponent(linkImage)}`}
          alt={`${linkImage}`}
        />
      </div>
    </div>
  );
}

export default PreviewImage;
