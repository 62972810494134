import React from 'react';
import './RadioChoiceStyle.scss';

function RadioChoiceItem({ choice, className, onChange }) {
  return (
    <div className="radio_item">
      <div className="form-check">
        <input
          className={`form-check-input ${className}`}
          type="radio"
          name={`radioChoice ${choice?.id}`}
          id={`radioChoice ${choice?.label} ${choice?.option}`}
          onChange={onChange}
        />
        <label
          className="form-check-label"
          htmlFor={`radioChoice ${choice?.label} ${choice?.option}`}
        >
          {choice?.label}
        </label>
      </div>
    </div>
  );
}

export default RadioChoiceItem;
