import React, { useEffect, useId, useState } from 'react';
import ja from '../../i18n/languages/ja.json';

export default function QuestionRanking({ question, setAnswers, onQuestionError }) {
  const [listOptionValues, setListOptionValues] = useState(
    question.options.map((option) => ({
      id: option.id,
      title: option.title,
      value: '',
    })),
  );

  const handleChangeDropdown = (optionId, value) => {
    setListOptionValues((listOptionValuesPrev) => {
      let temp = [...listOptionValuesPrev];

      const optionChange = temp.find((optionValue) => {
        return optionValue.id === optionId;
      });

      if (!value) {
        optionChange.value = value;
        return temp;
      }

      const optionOldIndex = temp.findIndex((optionValue) => {
        return optionValue.id === optionId;
      });
      const optionOldValueToNumber = Number(optionChange.value);
      const optionNewValueToNumber = Number(value);

      optionChange.value = value;

      const listValueExist = temp
        .filter((optionValue) => optionValue.value)
        .map((optionValue) => optionValue.value);

      // Update value
      if (optionOldValueToNumber || listValueExist.length !== new Set(listValueExist).size) {
        temp.some((optionValue) => {
          if (optionValue.id === optionId || !optionValue.value) return false;

          const valueToNumber = Number(optionValue.value);

          if (optionOldIndex + 1 < optionNewValueToNumber) {
            if (valueToNumber <= optionNewValueToNumber && valueToNumber > optionOldIndex + 1) {
              optionValue.value = (valueToNumber - 1).toString();
            }
          } else if (valueToNumber >= optionNewValueToNumber && valueToNumber < optionOldIndex + 1)
            optionValue.value = (valueToNumber + 1).toString();

          return false;
        });
      }

      // Sort value
      temp = temp.sort((a, b) => Number(b.value) - Number(a.value));

      // Sort value by Index
      for (let index = temp.length - 1; index >= 0; index -= 1) {
        const oldIndex = temp.findIndex((optionValue) => Number(optionValue.value) - 1 === index);

        if (oldIndex !== -1) {
          if (oldIndex > index) {
            for (let j = oldIndex; j > index; j -= 1) {
              [temp[j], temp[j - 1]] = [temp[j - 1], temp[j]];
            }
          } else {
            for (let j = oldIndex; j < index; j += 1) {
              [temp[j], temp[j + 1]] = [temp[j + 1], temp[j]];
            }
          }

          temp = [...temp];
        }
      }

      return temp;
    });
  };

  useEffect(() => {
    if (question.isHide || !question.is_required) {
      onQuestionError(false);
      return;
    }

    if (listOptionValues.some((optionValue) => !optionValue.value)) onQuestionError(true);
    else onQuestionError(false);
  }, [listOptionValues, question.isHide]);

  useEffect(() => {
    setAnswers((prevAnswers) => {
      const answersClone = [...prevAnswers];

      listOptionValues.forEach((optionValue) => {
        const answerExist = answersClone.find((answer) => answer.option_id === optionValue.id);

        const { value } = optionValue;

        if (!value) {
          if (answerExist) {
            const indexAnswerExist = answersClone.findIndex(
              (answer) => answer.option_id === optionValue.id,
            );

            answersClone.splice(indexAnswerExist, 1);
          }
        } else if (!answerExist) {
          answersClone.push({
            question_id: question.id,
            option_id: optionValue.id,
            value: value.toString(),
          });
        } else {
          answerExist.value = value.toString();
        }
      });

      return answersClone;
    });
  }, [listOptionValues]);

  return (
    <>
      {listOptionValues.map((option) => {
        return (
          <div key={option.id} className="question-ranking d-flex mb-2 align-items-center">
            <div
              className="dropdown_form mt-0 w-auto"
              style={{ gridTemplateColumns: '90% 10%', minWidth: '10rem' }}
            >
              <select
                className="form-select"
                onChange={(event) => handleChangeDropdown(option.id, event.target.value)}
                value={option.value}
              >
                <option key={useId()} value="">
                  {ja.select}
                </option>
                {Array(question.options.length)
                  .fill(0)
                  .map((value, index) => (
                    <option key={useId()} value={index + 1}>
                      {index + 1} {question.unit_name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="dropdown-title">{option.title}</div>
          </div>
        );
      })}
    </>
  );
}
