import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Spinner from './components/common/spinner/Spinner';

import '../assets/scss/index.scss';

import ToastMessage from './components/common/toast/ToastMessage';
import Dummy from './pages/Dummy/Dummy';
import ThankSubmit from './pages/ThankSubmit';
import Demo from './pages/Demo';
import HomePage from './pages/HomePage';
import QuestionnaireGroup from './pages/QuestionnaireGroup/QuestionnaireGroup';

function App() {
  return (
    <>
      <Spinner />
      <ToastMessage />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/questionnaire/:code" element={<Dummy />} />
        <Route path="/:companyCode/:code" element={<QuestionnaireGroup />} />
        <Route path="/thank" element={<ThankSubmit />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>
    </>
  );
}

export default App;
