import React from 'react';

export default function Date({ onChange }) {
  const handleChange = (event) => {
    const value = event.target.value.trim().replaceAll('-', '/');

    onChange(value);
  };

  return <input type="date" className="form-control" onChange={handleChange} />;
}
