import _ from 'lodash';

/* eslint-disable implicit-arrow-linebreak */

import constants from '../../constants';

const { QUESTION_TYPE_ENUM, OPTION_LOGIC_TYPE_ENUM } = constants;

const getListOptionLogicsByListAnswersSameQuestion = (
  listOptionLogics,
  questionType,
  listAnswersSameQuestion,
) => {
  const listOptionIdInAnswersParam = listAnswersSameQuestion.map(
    (answerParam) => answerParam.option_id,
  );

  return listOptionLogics.filter(
    (optionLogic) =>
      (![QUESTION_TYPE_ENUM.star, QUESTION_TYPE_ENUM.ranking].includes(questionType) &&
        listOptionIdInAnswersParam.includes(optionLogic.option_id)) ||
      ([QUESTION_TYPE_ENUM.star, QUESTION_TYPE_ENUM.ranking].includes(questionType) &&
        listAnswersSameQuestion.find(
          (answerParam) =>
            answerParam.value.toString() === optionLogic.value?.toString() &&
            answerParam.option_id === optionLogic.option_id,
        )),
  );
};

export const getListAllQuestionsInQuestionnaire = (questionnaire) =>
  [...questionnaire.questionnaire_template.questions, ...questionnaire.questions].map(
    (question, index) => {
      question.order = index;
      return question;
    },
  );

export const getListAllOptionLogicsInQuestionnaire = (questionnaire) =>
  [...questionnaire.questionnaire_template.questions, ...questionnaire.questions]
    .map((question) => question.options.map((option) => option.option_logics).flat())
    .flat();

export const setHideShowQuestionByListAnswers = (
  listOptionLogics,
  listAllQuestionsSort,
  listAnswers,
) => {
  const listAnswersGroupBy = _.groupBy(listAnswers, 'question_id');

  let isEndOfSurvey = false;
  let questionIdSkipTo = null;
  let isHideRemainingQuestion = false;
  const listQuestionsFilter = listAllQuestionsSort.map((question) => {
    if (isEndOfSurvey || isHideRemainingQuestion) {
      question.isHide = true;
      return question;
    }

    if (questionIdSkipTo && questionIdSkipTo !== question.id) {
      question.isHide = true;
      return question;
    }

    if (
      question.options.some((option) => option.option_logics.length !== 0) &&
      !listAnswersGroupBy[question.id] &&
      (question.is_required || question.options.some((option) => option.data?.is_required))
    ) {
      isHideRemainingQuestion = true;
    }

    if (questionIdSkipTo === question.id) questionIdSkipTo = null;

    if (listAnswersGroupBy[question.id]) {
      getListOptionLogicsByListAnswersSameQuestion(
        listOptionLogics,
        question.type,
        listAnswersGroupBy[question.id],
      ).every((optionLogic) => {
        if (optionLogic.type === OPTION_LOGIC_TYPE_ENUM.endOfSurvey) {
          isEndOfSurvey = true;
          return false;
        }

        questionIdSkipTo = optionLogic.question_id;
        return true;
      });
    }

    question.isHide = false;
    return question;
  });

  return listQuestionsFilter;
};
