import React from 'react';
import './ImageChoiceItem.scss';
import checkImageChoice from '../../../../../assets/imgs/check-image-choice-sp.png';

function ImageItem({ choice, className, onChange }) {
  return (
    <label className={`card d-block ${className || ''}`}>
      <div className="card__image">
        <img
          src={`${process.env.APP_URL_CMS}${encodeURIComponent(choice.image)}`}
          className="card-img-top"
          alt="..."
        />
      </div>
      <div className="card-body">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={onChange}
            name={`flexCheckDisabled ${choice?.id}`}
          />
          <div
            className="check-image-choice opacity-0"
            style={{ background: `url(${checkImageChoice})` }}
          />
          <span className="form-check-label">{choice?.label}</span>
        </div>
      </div>
    </label>
  );
}

export default ImageItem;
