import React from 'react';
import './MutipleChoice.scss';

function MultiChoiceItem({ choice, onChange }) {
  return (
    <div className="multiple_form">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name={`multiChoice ${choice?.id}`}
          id={`multiChoice ${choice?.label} ${choice?.option}`}
          onChange={onChange}
        />
        <label
          className="form-check-label"
          htmlFor={`multiChoice ${choice?.label} ${choice?.option}`}
        >
          {choice?.label}
        </label>
      </div>
    </div>
  );
}

export default MultiChoiceItem;
