import { createSlice } from '@reduxjs/toolkit';
import jscookie from 'js-cookie';

const initialState = {
  token: null,
  userInfo: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, data) => {
      const { payload } = data;
      state.token = payload.token;
      state.userInfo = payload.user;
      if (payload.token) {
        jscookie.set('token', payload.token, { expires: 30 });
      }
    },
    logout: (state) => {
      state = { ...initialState };
      jscookie.remove('token');
      return state;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
