import React from 'react';
import Bigben from '../../../../../assets/imgs/bigben.jpg';
import './IconChoiceItem.scss';

function IconChoiceCustom({ choice }) {
  return (
    <div className="icon_custom">
      <div className="form-check">
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          {choice?.label}
        </label>
        <img src={Bigben} alt="" className="icon" />
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
        />
        {/* <label className="form-check-label" htmlFor="flexRadioDefault1">
          {choice.label}
        </label> */}
      </div>
    </div>
  );
}

export default IconChoiceCustom;
