import React from 'react';
import './ImageChoiceItem.scss';
import checkImageChoice from '../../../../../assets/imgs/check-image-choice-sp.png';

function AvatarItem({ choice, className, onChange }) {
  return (
    <label className={`card card-avatar d-block ${className || ''}`}>
      <div className="avatar__image">
        <div className="avatar-wrapper">
          {choice.image ? (
            <img src={`${process.env.APP_URL_CMS}${encodeURIComponent(choice.image)}`} alt="..." />
          ) : (
            <div className="avatar-default">
              <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
              </svg>
            </div>
          )}
        </div>
      </div>

      <div className="card-body">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={onChange}
            value={choice.id}
            name={`flexCheckDisabled ${choice?.id}`}
          />
          <div
            className="check-image-choice opacity-0"
            style={{ background: `url(${checkImageChoice})` }}
          />
          <span className="form-check-label">{choice?.label}</span>
        </div>
      </div>
    </label>
  );
}

export default AvatarItem;
