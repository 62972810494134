import React from 'react';
import { useLocation } from 'react-router';
import ja from '../i18n/languages/ja.json';
import './ThankSubmit.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';

function ThankSubmit() {
  let gbpComment, gbpUrl;
  const { state } = useLocation();
  if (state) ({ gbpComment, gbpUrl } = state);
  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(gbpComment);
    toast.success(ja.gbp_comment_copy_success);
  };

  return (
    <Container className="wrapper">
      {gbpComment && gbpUrl ? (
        <>
          <Row>
            <Col className="thank_content">
              <h2 className="text-danger">
                {ja.header_title}
                {ja.gbp_evaluate_discription}
              </h2>
              <textarea rows={6} value={gbpComment} readOnly />
              <small>{ja.gbp_copy_button_description}</small>
            </Col>
          </Row>

          <Row className="justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center">
            <Col xs="4" sm="3" md="3" lg="3">
              <Button variant={'primary'} onClick={copyToClipboard} disabled={false}>
                {ja.gbp_comment_copy}
              </Button>
            </Col>
            <Col xs="auto" sm="auto" md="auto" lg="auto" />
            <Col xs="4" sm="3" md="3" lg="3">
              <Button
                variant={'success'}
                href={gbpUrl}
                target="_blank"
                disabled={false}
                className="btn_submit_gbp"
              >
                {ja.navigate_to_gbp}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col className="thank_content">
            <h2 className="text-danger">{ja.header_title}</h2>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ThankSubmit;
