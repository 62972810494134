import React from 'react';
import './QuestionHeader.scss';

function QuestionHeader({ questionNumber, questionName, isRequired, requiredText }) {
  return (
    <div className="question__header">
      <p className="question__order">{`Q${questionNumber}.`}</p>
      <div className="group_list">
        <span className="question__name">{questionName}</span>
        {isRequired && <span className="question__required">*</span>}
      </div>
    </div>
  );
}

export default QuestionHeader;
