import React, { useEffect, useState } from 'react';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import TextBoxTotalNumber from '../../common/form-questions/textbox-total-number/TextBoxTotalNumber';

export default function QuestionTextBoxTotalNumber({
  question,
  handleError,
  setAnswers,
  onQuestionError,
}) {
  const [listTextBoxValue, setListTextBoxValue] = useState([]);
  const [total, setTotal] = useState(0);
  const [showErrorTotal, setShowErrorTotal] = useState(false);
  const handleChangeValueTextBoxTotal = (questionId, optionId, value) => {
    setListTextBoxValue((listTextBoxValuePrev) => {
      const temp = [...listTextBoxValuePrev];

      const textboxValue = temp.find((textBoxValue) => textBoxValue.option_id === optionId);

      if (textboxValue) textboxValue.value = value;
      else temp.push({ option_id: optionId, value });

      return temp;
    });

    setAnswers((prevAnswers) => {
      const answersClone = [...prevAnswers];

      const indexAnswerExist = answersClone.findIndex(
        (answer) => answer.question_id === questionId && answer.option_id === optionId,
      );

      const answerExist = answersClone[indexAnswerExist];

      if (!value.toString()) {
        answersClone.splice(indexAnswerExist, 1);

        return answersClone;
      }

      if (answerExist) answerExist.value = value.toString();
      else
        answersClone.push({
          question_id: questionId,
          option_id: optionId,
          value: value.toString(),
        });

      return answersClone;
    });
  };

  useEffect(() => {
    setTotal(
      listTextBoxValue.reduce(
        (sum, textBoxValue) => Number(Number(sum + (Number(textBoxValue.value) || 0)).toFixed(10)),
        0,
      ),
    );
  }, [listTextBoxValue]);

  useEffect(() => {
    if (question.isHide || total === question.textbox_total) {
      onQuestionError(false);
      return;
    }

    if (
      total !== question.textbox_total &&
      listTextBoxValue.filter((textBoxValue) => !!textBoxValue.value).length !== 0
    )
      onQuestionError(true);
    else onQuestionError(false);
  }, [total, listTextBoxValue]);

  useEffect(() => {
    if (total === question.textbox_total) {
      setShowErrorTotal(true);
    } else {
      setShowErrorTotal(false);
    }
  }, [total]);
  return (
    <>
      {question.options.map((option) => (
        <TextBoxTotalNumber
          key={option.id}
          isHide={question.isHide}
          isRequired={option.data.is_required}
          format={option.data.format}
          title={option.title}
          onChange={(value) => handleChangeValueTextBoxTotal(question.id, option.id, value)}
          onError={(isError) => handleError(option.id, isError)}
        />
      ))}
      <hr className="my-3" />
      <div className="textbox-total-number-suggest">
        <div className="suggest">※合計値になるように回答してください。</div>
        <div className="total">
          {total}/{question.textbox_total}{' '}
          {listTextBoxValue.filter((item) => item.value).length !== 0 &&
            (showErrorTotal ? <BsCheckLg color="green" /> : <BsXLg color="red" />)}
        </div>
      </div>
    </>
  );
}
