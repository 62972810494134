import React, { useState } from 'react';
import './TextFormItem.scss';

function TextFormItem({ isRequired, requiredText, textDescription }) {
  const [value, setValue] = useState('');

  return (
    <div className="text_form">
      {isRequired && (
        <div className="form__title">
          {requiredText}
          <span>*</span>
        </div>
      )}
      <div className="form-check">
        <input
          className="form-control"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>
      <p>{textDescription}</p>
    </div>
  );
}

export default TextFormItem;
