import React, { useState } from 'react';

export default function Password({ onChange }) {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value.trim());

    if (value !== event.target.value.trim()) onChange(event.target.value.trim());
  };

  return (
    <input
      type="password"
      className="form-control"
      value={value}
      autoComplete="new-password"
      onChange={handleChange}
    />
  );
}
