import React from 'react';
import './DropDownItem.scss';

function DropDownItem({ choices, onChange }) {
  return (
    <div className="dropdown_form">
      <select className="form-select" defaultValue="" onChange={onChange}>
        {choices &&
          choices.map((choice) => (
            <option key={choice.id} value={choice.id}>
              {choice.title}
            </option>
          ))}
      </select>
    </div>
  );
}

export default DropDownItem;
