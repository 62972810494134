const REQUIRED_TEXT = 'Required';
const URL_NOT_REDIRECT = '';

const MAX_SIZE_IMAGE = 10;

const MAX_FILE_UPLOAD = 50;

const MAX_UPLOAD_PER_TIME = 5;

const imageFormat = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'];

const ADMIN_URL = 'https://cms.dss.hblab.dev/';

const USER_MEDIA_VIDEO_CONSTRAINTS = {
  height: { ideal: 1920 },
  frameRate: { ideal: 30 },
};

const CAMERA_WIDTH_CROP_PERCENT = 70;
const CAMERA_HEIGHT_CROP_PERCENT = 60;

const QUESTION_TYPE_ENUM = {
  radioNoImageVertical: 1,
  checkboxNoImageVertical: 2,
  checkboxImageHorizontal: 3,
  radioNoImageHorizontal: 4,
  star: 5,
  dropdown: 7,
  textBoxSentences: 8,
  textBoxWords: 9,
  textBoxTotalNumber: 10,
  ranking: 12,
  uploadImage: 14,
  radioImageHorizontal: 15,
  selectStaff: 16,
};

const OPTION_LOGIC_TYPE_ENUM = {
  skip: 1,
  endOfSurvey: 2,
};

const DATA_FORMAT_ENUM = {
  letters: 1,
  numbers: 2,
  alphanumeric: 3,
  email: 4,
  date: 5,
  password: 6,
};

const DATA_FORMAT_REGEX = {
  [DATA_FORMAT_ENUM.letters]: /^(.|\s)*$/,
  [DATA_FORMAT_ENUM.numbers]: /^-?(0|[1-9]\d*)(\.\d+)?$/,
  [DATA_FORMAT_ENUM.alphanumeric]: /^\w+$/,
  [DATA_FORMAT_ENUM.email]: /^(?!.{255})([\w-.]+@([\w-]+\.)+[\w-]+)$/,
  [DATA_FORMAT_ENUM.date]: /^\d{4}(\/)(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])$/,
  [DATA_FORMAT_ENUM.password]: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,255}$/,
  phoneNumber: /^0(\d+)?$/,
};

export default {
  REQUIRED_TEXT,
  URL_NOT_REDIRECT,
  MAX_SIZE_IMAGE,
  MAX_FILE_UPLOAD,
  imageFormat,
  ADMIN_URL,
  MAX_UPLOAD_PER_TIME,
  USER_MEDIA_VIDEO_CONSTRAINTS,
  CAMERA_WIDTH_CROP_PERCENT,
  CAMERA_HEIGHT_CROP_PERCENT,
  QUESTION_TYPE_ENUM,
  OPTION_LOGIC_TYPE_ENUM,
  DATA_FORMAT_ENUM,
  DATA_FORMAT_REGEX,
};
