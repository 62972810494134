import React from 'react';
import './loading.scss';

function Loading() {
  return (
    <div className="spinner-wrapper">
      <div className="spinner-loading text-light">
        <div className="spinner-border" role="status" />
      </div>
    </div>
  );
}

export default Loading;
