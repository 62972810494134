import React, { useState } from 'react';
import StarRatings from 'react-star-ratings';
import './StarChoiceItem.scss';

function StarChoiceItem({ numberOfStars, onChange }) {
  const [rating, setRating] = useState();

  const changeRating = (newRating) => {
    setRating(newRating);
    onChange(newRating);
  };

  return (
    <StarRatings
      starRatedColor="#f7d10f"
      starHoverColor="#f7d10f"
      rating={rating}
      changeRating={changeRating}
      numberOfStars={numberOfStars}
      starDimension="40px"
      name="rating"
    />
  );
}

export default StarChoiceItem;
