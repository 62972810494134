/* eslint-disable camelcase */

import api from '../api/Interceptors';

const ClientApis = {
  showQuestionnaire: (code, params) => api.get(`questionnaires/${code}`, { params }),
  showGroupQuestionnaire: (code) => api.get(`/questionnaire-groups/${code}`),
  updateAnswer: ({ questionnaire_id, answers }) =>
    api.post('answers', { questionnaire_id, answers }),
};

export default ClientApis;
