import React from 'react';
import RadioChoiceItem from '../form-questions/radio-choices/RadioChoiceItem';
import './RadioGroupForm.scss';

function RadioGroupFormItem({ choices, textDescription, className }) {
  return (
    <div className="group_form">
      <p>{textDescription}</p>
      <div className="group_list">
        {choices &&
          choices.map((choice) => <RadioChoiceItem choice={choice} className={className} />)}
      </div>
    </div>
  );
}

export default RadioGroupFormItem;
