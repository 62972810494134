import React from 'react';
import './RadioChoiceStyle.scss';

function RadioChoiceCustom({ choice, onChange }) {
  return (
    <div className="radio_custom px-2">
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={`radioCustom ${choice?.id}`}
          id={`radioCustom ${choice?.label} ${choice?.option}`}
          onChange={onChange}
        />
        <label
          className="form-check-label pb-1 text-center"
          htmlFor={`radioCustom ${choice?.label} ${choice?.option}`}
        >
          {choice?.label}
        </label>
      </div>
    </div>
  );
}

export default RadioChoiceCustom;
