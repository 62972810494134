import React from 'react';

export default function Letter({ isSentences, onChange }) {
  const handleChange = (event) => {
    const { value } = event.target;

    onChange(value);
  };

  return (
    <div>
      {isSentences ? (
        <textarea className="form-control" rows={6} onChange={handleChange} />
      ) : (
        <input className="form-control" onChange={handleChange} />
      )}
    </div>
  );
}
