import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import './QuestionnaireGroup.scss';
import DocumentMeta from 'react-document-meta';
import questionnaireService from '../../services/questionnaire';
import Loading from '../../components/common/loading/Loader';
import ja from '../../i18n/languages/ja.json';
import coenavi from '../../../assets/imgs/coenavi_OL-01.52abb84c.png';

export default function QuestionnaireGroup() {
  const [questionnaireGroup, setQuestionnaireGroup] = useState([]);
  const [titleQuestionnaireGroup, setTitleQuestionnaireGroup] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { companyCode, code } = useParams();
  const fetchQuestionnaireGroup = async () => {
    setIsLoading(true);
    try {
      const res = await questionnaireService.showGroupQuestionnaire(code);
      setTitleQuestionnaireGroup(res.data.data.name);
      setQuestionnaireGroup(res.data.data.questionnaires);
      setIsLoading(false);
    } catch (err) {
      setTitleQuestionnaireGroup(ja.error_message_status_questionnaire_group_not_public);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchQuestionnaireGroup();
  }, []);
  const meta = {
    title: titleQuestionnaireGroup,
  };
  return (
    <div className="questionnaire-group">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DocumentMeta {...meta} />
          <div className="header">{titleQuestionnaireGroup}</div>
          {questionnaireGroup.length !== 0 && (
            <div className="content_group">対象アンケートを選択してください</div>
          )}
          <main>
            {questionnaireGroup &&
              questionnaireGroup.map((question, index) => (
                <div
                  key={index}
                  className="item"
                  onClick={() => navigate(`/questionnaire/${question.code}`)}
                >
                  {question?.store?.name} - {question.title}
                </div>
              ))}
          </main>
        </>
      )}
    </div>
  );
}
