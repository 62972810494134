import React, { useEffect, useState } from 'react';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import constants from '../../../../constants';
import './TextBoxTotalNumber.scss';

const { DATA_FORMAT_REGEX } = constants;

function TextBoxTotalNumber({ isHide, isRequired, format, title, onChange, onError }) {
  const [value, setValue] = useState('');
  const [isShowError, setIsShowError] = useState(false);
  const [isValid, setIsValid] = useState(!isRequired);

  useEffect(() => {
    if (!isHide) onError(!isValid);
    else onError(false);
  }, [isValid, isHide]);

  const handleChange = (event) => {
    if (!isShowError) setIsShowError(true);

    const valueTrim = event.target.value.trim();
    setValue(valueTrim);
    if (!valueTrim) setIsShowError(false);

    if (value === valueTrim) return;

    onChange(valueTrim);

    if ((valueTrim && DATA_FORMAT_REGEX[format].test(valueTrim)) || (!valueTrim && !isRequired)) {
      setIsValid(true);
      return;
    }
    setIsValid(false);
  };

  return (
    <div className="textbox-total-number">
      <div className="title-wrap">
        <div className="textbox-title">{title}</div>
        <div className="required">{isRequired && <span>*</span>}</div>
      </div>
      <div>
        <input className="form-control" value={value} onChange={handleChange} />
      </div>
      <div className="validate">
        {isShowError && (!isValid ? <BsXLg color="red" /> : <BsCheckLg color="green" />)}
      </div>
      <div className="text_format">(半角数字)</div>
    </div>
  );
}

export default TextBoxTotalNumber;
