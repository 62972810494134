/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import DocumentMeta from 'react-document-meta';
import MultiChoiceItem from '../../components/common/form-questions/form-checkbox/MultipleChoiceItem';
import ImageItem from '../../components/common/form-questions/image-choices/ImageChoiceItem';
import RadioChoiceCustom from '../../components/common/form-questions/radio-choices/RadioChoiceCustom';
import RadioChoiceItem from '../../components/common/form-questions/radio-choices/RadioChoiceItem';
import QuestionHeader from '../../components/common/header-questions/QuestionHeader';
import UploadForm from '../../components/common/upload-form/UploadForm';
import './Dummy.scss';
import imageServices from '../../services/image_service';
import ja from '../../i18n/languages/ja.json';
import questionnaireService from '../../services/questionnaire';
import constants from '../../constants/index';
import Loading from '../../components/common/loading/Loader';
import PreviewImage from '../../components/common/previewImage/PreviewImage';
import {
  getListAllOptionLogicsInQuestionnaire,
  getListAllQuestionsInQuestionnaire,
  setHideShowQuestionByListAnswers,
} from './utils';
import StarChoiceItem from '../../components/common/form-questions/star-choices/StarChoiceItem';
import DropDownItem from '../../components/common/form-questions/dropdown/DropDownItem';
import TextAreaItem from '../../components/common/form-questions/textarea-choices/TextAreaItem';
import QuestionTextBoxTotalNumber from '../../components/dummy/question-textbox-total-number/QuestionTextBoxTotalNumber';
import QuestionRanking from '../../components/dummy/QuestionRanking';
import ImageRadioChoice from '../../components/common/form-questions/image-radio-choices/ImageRadioChoice';
import AvatarItem from '../../components/common/form-questions/image-choices/AvatarChoiceItem';
import coenavi from '../../../assets/imgs/coenavi_OL-01.52abb84c.png';

const { QUESTION_TYPE_ENUM } = constants;

function Dummy() {
  const [questionnaire, setQuestionnaire] = useState();
  const [staffs, setStaffs] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [listOptionIdErrors, setListOptionIdErrors] = useState([]);
  const [listQuestionIdErrors, setListQuestionIdErrors] = useState([]);
  const [listAllQuestions, setListAllQuestions] = useState([]);
  const [listAllOptionLogics, setListAllOptionLogics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [urlFile, setUrlFile] = useState();
  const [status, setStatus] = useState(false);
  const [titleQuestionnaire, setTitleQuestionnaire] = useState('');
  const navigate = useNavigate();
  const { code } = useParams();

  const fetchQuestionnaires = async () => {
    setIsLoading(true);
    try {
      const res = await questionnaireService.showQuestionnaire(code);
      const result = res.data.data;
      const questionnaireResult = result.questionnaire;
      setTitleQuestionnaire(questionnaireResult.title);
      setQuestionnaire(questionnaireResult);
      setStaffs(result.staffs.sort((a, b) => b.id - a.id));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setStatus(true);
      setTitleQuestionnaire(ja.error_message_status_questionnaire_not_public);
    }
  };

  const handleError = (optionId, isError) => {
    setListOptionIdErrors((listOptionIdErrorsPrev) => {
      const temp = [...listOptionIdErrorsPrev];

      const indexOptionIdError = listOptionIdErrorsPrev.findIndex(
        (optionIdError) => optionIdError === optionId,
      );

      if (isError && indexOptionIdError < 0) {
        temp.push(optionId);
        return temp;
      }

      if (!isError && indexOptionIdError >= 0) {
        temp.splice(indexOptionIdError, 1);
        return temp;
      }

      return listOptionIdErrorsPrev;
    });
  };

  const handleQuestionError = (questionId, isError) => {
    setListQuestionIdErrors((listQuestionIdErrorsPrev) => {
      const temp = [...listQuestionIdErrorsPrev];

      const indexQuestionIdError = listQuestionIdErrorsPrev.findIndex(
        (questionIdError) => questionIdError === questionId,
      );

      if (isError && indexQuestionIdError < 0) {
        temp.push(questionId);
        return temp;
      }

      if (!isError && indexQuestionIdError >= 0) {
        temp.splice(indexQuestionIdError, 1);
        return temp;
      }

      return listQuestionIdErrorsPrev;
    });
  };

  const handleChangeValue = (questionId, optionId, value) => {
    setAnswers((prevAnswers) => {
      const answersClone = [...prevAnswers];

      const answerExist = answersClone.find(
        (answer) => answer.question_id === questionId && answer.option_id === optionId,
      );

      if (answerExist) answerExist.value = value.toString();
      else
        answersClone.push({
          question_id: questionId,
          option_id: optionId,
          value: value.toString(),
        });

      return answersClone;
    });
  };

  const handleChangeStaff = (questionId, optionId, value, isDelete = false) => {
    setAnswers((prevAnswers) => {
      const answersClone = [...prevAnswers];

      if (isDelete) {
        const indexAnswerExist = answersClone.findIndex(
          (answer) => answer.option_id === optionId && answer.value === value,
        );

        if (indexAnswerExist) answersClone.splice(indexAnswerExist, 1);

        return answersClone;
      }

      answersClone.push({
        question_id: questionId,
        option_id: optionId,
        value: value.toString(),
      });

      return answersClone;
    });
  };

  const handleChangeDropdown = (questionId, event) => {
    const { value } = event.target;

    const indexAnswer = answers.findIndex((answer) => answer.question_id === questionId);

    if (indexAnswer >= 0) answers.splice(indexAnswer, 1);

    if (!value) {
      setAnswers([...answers]);
      return;
    }

    const newAnswer = {
      question_id: questionId,
      option_id: Number(value),
    };

    setAnswers([...answers, { ...newAnswer }]);
  };

  const handleChangeOption = (option, question) => {
    const newAnswer = {
      question_id: question.id,
      option_id: option.id,
    };

    if (answers.length === 0) {
      setAnswers([...answers, { ...newAnswer }]);
      return;
    }

    const indexAnswer = answers.findIndex((answer) => answer.question_id === question.id);
    if (indexAnswer >= 0) {
      answers.splice(indexAnswer, 1);
    }
    setAnswers([...answers, { ...newAnswer }]);
  };

  const handleChangeMultiple = (e, option, question) => {
    if (!e.target.checked) {
      const indexAnswer = answers.findIndex(
        (answer) => answer.question_id === question.id && answer.option_id === option.id,
      );
      answers.splice(indexAnswer, 1);
      setAnswers([...answers]);
      return;
    }
    const newAnswer = {
      question_id: question.id,
      option_id: option.id,
    };
    setAnswers([...answers, { ...newAnswer }]);
  };

  const handleChangeUrl = (data, questionId) => {
    const questionCurrent = listAllQuestions.find(
      (question) => question.type === 14 && question.id === questionId,
    );

    const { options } = questionCurrent;
    let newUrlList = [];

    if (urlFile && urlFile[questionId]?.length > 0) {
      newUrlList = [...urlFile[questionId], ...data];
    } else {
      newUrlList = [...data];
    }

    if (newUrlList.length > constants.MAX_FILE_UPLOAD) {
      toast.error(ja.error_message_max_file_upload_all);
      return;
    }

    setUrlFile({
      ...urlFile,
      [questionId]: newUrlList,
    });

    const newAnswer = {
      question_id: questionCurrent.id,
      option_id: options[0].id,
      value: newUrlList.join(' / '),
    };

    if (answers.length === 0) {
      setAnswers([...answers, { ...newAnswer }]);
      return;
    }

    const indexAnswer = answers.findIndex((answer) => answer.question_id === questionCurrent.id);
    if (indexAnswer >= 0) {
      answers.splice(indexAnswer, 1);
    }

    setAnswers([...answers, { ...newAnswer }]);
  };

  const handleRemoveImage = (indexImage, questionId) => {
    if (urlFile && urlFile[questionId]) {
      urlFile[questionId].splice(indexImage, 1);
      setUrlFile({ ...urlFile, [questionId]: [...urlFile[questionId]] });
    }

    if (answers && answers.length > 0) {
      const indexAnswer = answers.findIndex((answer) => answer.question_id === questionId);

      if (indexAnswer >= 0) {
        const { value } = answers[indexAnswer];
        const arrayImages = value.split(' / ');

        if (arrayImages.length === 1) {
          answers.splice(indexAnswer, 1);

          setAnswers([...answers]);
          return;
        }

        arrayImages.splice(indexImage, 1);

        const newAnswer = {
          ...answers[indexAnswer],
          value: arrayImages.join(' / '),
        };

        answers.splice(indexAnswer, 1);
        setAnswers([newAnswer, ...answers]);
      }
    }
  };

  const handleUploadFile = async (e, questionId) => {
    const { files } = e.target;
    if (!files) return;
    if (files.length > constants.MAX_UPLOAD_PER_TIME) {
      toast.error(ja.error_message_max_file_upload_per_time);
      return;
    }

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        if (files[i].size > 1024 * 1024 * constants.MAX_SIZE_IMAGE) {
          toast.error(ja.error_message_max_size);
          return;
        }
      }
    }

    for (let i = 0; i < files.length; i += 1) {
      const fileExtenstion = files[i].name.split('.').pop();

      if (!(fileExtenstion && constants.imageFormat.includes(fileExtenstion))) {
        toast.error(ja.error_message_wrong_file_extension);
        return;
      }
    }

    const dataForm = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      dataForm.append('images', files[i], encodeURI(files[i].name));
    }

    try {
      const res = await imageServices.uploadImageList('upload/images', dataForm);
      const { data } = res;
      handleChangeUrl(data.data, questionId);
    } catch (err) {
      toast.error(ja.error_message_upload_image_server);
    }
  };

  const handleCamera = async (source, questionId) => {
    const base64Response = await fetch(source);
    const blob = await base64Response.blob();

    const dataForm = new FormData();
    dataForm.append('images', blob, encodeURI(blob.name));

    try {
      const res = await imageServices.uploadImageList('upload/images', dataForm);
      const { data } = res;
      handleChangeUrl(data.data, questionId);
    } catch (err) {
      toast.error(ja.error_message_upload_image_server);
    }
  };

  const showQuestion = (options, question) => {
    let questionInfo;
    switch (question.type) {
      case QUESTION_TYPE_ENUM.radioNoImageVertical:
        questionInfo = options.map((option) => (
          <RadioChoiceItem
            choice={{
              label: option.title,
              id: question.id,
              option: option.id,
            }}
            className="me-3"
            onChange={() => handleChangeOption(option, question)}
            key={option.id}
          />
        ));
        return questionInfo;

      case QUESTION_TYPE_ENUM.checkboxNoImageVertical:
        questionInfo = options.map((option) => (
          <MultiChoiceItem
            choice={{
              label: option.title,
              id: question.id,
              option: option.id,
            }}
            className="me-3"
            onChange={(e) => handleChangeMultiple(e, option, question)}
            key={option.id}
          />
        ));
        return questionInfo;

      case QUESTION_TYPE_ENUM.checkboxImageHorizontal:
        questionInfo = options.map((option) => (
          <ImageItem
            choice={{
              label: option.title || option.data.value,
              image: option.image,
              id: question.id,
              option: option.id,
            }}
            onChange={(e) => handleChangeMultiple(e, option, question)}
            key={option.id}
          />
        ));
        return <div className="image_section">{questionInfo}</div>;

      case QUESTION_TYPE_ENUM.radioNoImageHorizontal:
        questionInfo = options.map((option) => (
          <RadioChoiceCustom
            choice={{
              label: option.title || option.data.value,
              id: question.id,
              option: option.id,
            }}
            onChange={() => handleChangeOption(option, question)}
            key={option.id}
          />
        ));
        return <div className="radio_section">{questionInfo}</div>;

      case QUESTION_TYPE_ENUM.star:
        return (
          <StarChoiceItem
            numberOfStars={question.options[0].data.number_of_choices}
            onChange={(rating) => handleChangeValue(question.id, question.options[0].id, rating)}
          />
        );

      case QUESTION_TYPE_ENUM.dropdown:
        return (
          <DropDownItem
            choices={[{ id: '', title: ja.select }, ...options]}
            onChange={(event) => handleChangeDropdown(question.id, event)}
          />
        );

      case QUESTION_TYPE_ENUM.textBoxSentences:
        return options.map((option) => (
          <TextAreaItem
            key={option.id}
            isHide={question.isHide}
            isRequired={option.data.is_required}
            format={option.data.format}
            title={option.title}
            isSentences
            onChange={(value) => handleChangeValue(question.id, option.id, value)}
            onError={(isError) => handleError(option.id, isError)}
          />
        ));

      case QUESTION_TYPE_ENUM.textBoxWords:
        return options.map((option) => (
          <TextAreaItem
            key={option.id}
            isHide={question.isHide}
            isRequired={option.data.is_required}
            format={option.data.format}
            title={option.title}
            isSentences={false}
            onChange={(value) => handleChangeValue(question.id, option.id, value)}
            onError={(isError) => handleError(option.id, isError)}
          />
        ));
      case QUESTION_TYPE_ENUM.textBoxTotalNumber:
        return (
          <QuestionTextBoxTotalNumber
            question={question}
            handleError={handleError}
            setAnswers={setAnswers}
            onQuestionError={(isError) => handleQuestionError(question.id, isError)}
          />
        );

      case QUESTION_TYPE_ENUM.ranking:
        return (
          <QuestionRanking
            question={question}
            setAnswers={setAnswers}
            onQuestionError={(isError) => handleQuestionError(question.id, isError)}
          />
        );

      case QUESTION_TYPE_ENUM.uploadImage:
        questionInfo = (
          <>
            <UploadForm
              handleClick={handleUploadFile}
              // handleCamera={handleCamera}
              className="me-3"
              key={question.id}
              questionId={question.id}
            />
            {urlFile && urlFile[question.id]?.length > 0 && (
              <div className="image__upload">
                {Array.isArray(urlFile[question.id]) &&
                  urlFile[question.id].map((url, indexImage) => (
                    <div key={url}>
                      <PreviewImage
                        linkImage={url}
                        indexImage={indexImage}
                        removeImage={() => handleRemoveImage(indexImage, question.id)}
                      />
                    </div>
                  ))}
              </div>
            )}
          </>
        );
        return questionInfo;

      case QUESTION_TYPE_ENUM.radioImageHorizontal:
        return (
          <div className="image_section">
            {options.map((option) => (
              <ImageRadioChoice
                choice={{
                  label: option.title || option.data.value,
                  image: option.image,
                  id: question.id,
                  option: option.id,
                }}
                onChange={() => handleChangeOption(option, question)}
                key={option.id}
              />
            ))}
          </div>
        );

      case QUESTION_TYPE_ENUM.selectStaff:
        return (
          <div className="image_section">
            {staffs.map((staff) => (
              <AvatarItem
                choice={{
                  label: staff.name,
                  image: staff.avatar,
                  id: staff.id,
                }}
                onChange={(e) =>
                  handleChangeStaff(
                    question.id,
                    question.options[0].id,
                    e.target.value,
                    !e.target.checked,
                  )
                }
                key={staff.id}
              />
            ))}
          </div>
        );

      default:
        return <></>;
    }
  };

  const checkRequired = () => {
    const listQuestionsShowRequired = listAllQuestions.filter(
      (question) =>
        !question.isHide &&
        (question.is_required || question.options.some((option) => option.data?.is_required)),
    );

    if (answers.length === 0 && listQuestionsShowRequired.length > 0) return true;

    return listQuestionsShowRequired.some(
      (question) =>
        !answers.some(
          (answer) =>
            answer.question_id === question.id &&
            (![
              QUESTION_TYPE_ENUM.textBoxSentences,
              QUESTION_TYPE_ENUM.textBoxWords,
              QUESTION_TYPE_ENUM.textBoxTotalNumber,
            ].includes(question.type) ||
              (answer.value &&
                question.options
                  .filter((option) => option.data?.is_required)
                  .some((option) => option.id === answer.option_id))),
        ),
    );
  };

  const isErrorExists = checkRequired() || listOptionIdErrors.length || listQuestionIdErrors.length;

  const getGbpComment = () => {
    let gbpComment, gbpCommentRelateQuestionId, answeredValue;
    let gbpStandardValues = [];

    const answerFiltered = answers.filter((answer) => {
      return (
        parseInt(answer.question_id) == parseInt(questionnaire.gbp_question_id) && answer.value
      );
    });
    if (!answerFiltered.length) return false;
    gbpComment = answerFiltered[0].value;

    for (const question of questionnaire.questionnaire_template.questions) {
      if (question.options.length == 1) {
        const option = question.options[0];
        if (option.question_id == questionnaire.gbp_question_id) {
          gbpStandardValues.push(parseInt(option.value));

          if (!gbpCommentRelateQuestionId) {
            gbpCommentRelateQuestionId = question.options[0].question_id;
            answeredValue = answers.filter((answer) => {
              return parseInt(answer.question_id) == parseInt(gbpCommentRelateQuestionId);
            })[0].value;
          }
        }
      }
    }

    if (!gbpStandardValues.length && !answeredValue) return false;
    if (!gbpStandardValues.includes(parseInt(answeredValue))) return false;
    return gbpComment;
  };

  const handleAnswer = async () => {
    if (answers && answers.length === 0) {
      navigate('/thank');
      return;
    }

    setIsLoading(true);
    try {
      const res = await questionnaireService.updateAnswer({
        questionnaire_id: questionnaire.id,
        answers,
      });
      setIsLoading(false);

      if (res.data.success) {
        if (questionnaire.gbp_question_id && questionnaire.gbp_url) {
          const gbpComment = await getGbpComment();
          if (gbpComment) {
            navigate('/thank', {
              state: { gbpComment: gbpComment, gbpUrl: questionnaire.gbp_url },
            });
          } else {
            navigate('/thank');
          }
        } else {
          navigate('/thank');
        }
      }
    } catch (err) {
      window.alert(err.response.data.error.description);
      setIsLoading(false);
    }
  };

  const questionEmpty = (questions) => {
    if (questions && questions?.length === 0) {
      return true;
    }

    let countEmpty = 0;
    if (questions) {
      questions.forEach((question) => {
        if (!question.title) {
          countEmpty += 1;
        }
      });
    }

    if (countEmpty === questions?.length) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    fetchQuestionnaires();
  }, []);
  const meta = {
    title: titleQuestionnaire,
  };

  useEffect(() => {
    if (!questionnaire) return;

    const listAllQuestionsInQuestionnaire = getListAllQuestionsInQuestionnaire(questionnaire);

    const listAllOptionLogicsInQuestionnaire = getListAllOptionLogicsInQuestionnaire(questionnaire);

    setListAllQuestions(
      setHideShowQuestionByListAnswers(
        listAllOptionLogics,
        listAllQuestionsInQuestionnaire,
        answers,
      ),
    );
    setListAllOptionLogics(listAllOptionLogicsInQuestionnaire);
  }, [questionnaire]);

  useEffect(() => {
    setListAllQuestions(
      setHideShowQuestionByListAnswers(listAllOptionLogics, listAllQuestions, answers),
    );
  }, [answers]);

  return (
    <div className="wrapper">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DocumentMeta {...meta} />
          <div className="header">{titleQuestionnaire}</div>
          <main>
            {listAllQuestions &&
              listAllQuestions.map((question) => {
                const { is_required: isRequired, title, options } = question;
                return (
                  <section className={!question.isHide ? 'active' : null} key={question.id}>
                    <QuestionHeader
                      questionNumber={question.order + 1}
                      questionName={title}
                      isRequired={isRequired}
                    />
                    {showQuestion(options, question)}
                  </section>
                );
              })}
            {!questionEmpty(listAllQuestions) && (
              <section className="active">
                {!status && (
                  <Button
                    variant={isErrorExists ? 'secondary' : 'primary'}
                    onClick={handleAnswer}
                    disabled={isErrorExists}
                    className="btn_submit"
                  >
                    {ja.btn_send}
                  </Button>
                )}
              </section>
            )}
          </main>
        </>
      )}
    </div>
  );
}

export default Dummy;
